<template>  
  <CCard class="h-100 m-0 loyalty moment_points" v-bind:class="{'boxed': boxed === true}">      
    <CCardBody v-bind:class="{'p-0': boxed === false}">
      <CRow class="h-100 m-0">
        <CCol cols="12" class="h-100 p-0">
          <div class="h-100 d-flex align-items-center justify-content-center">
            <div class="flex-grow-1 d-flex align-items-center justify-content-center">
              
              <div class="h-100 d-flex align-items-center justify-content-center currency">
                <img v-if="momentPointsCurrency === 'points'" :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                <i v-if="momentPointsCurrency === 'euro'" class="fa-solid fa-euro-sign"></i>
              </div>

              <div class="h-100 pl-2 d-flex align-items-center justify-content-center flex-column">
                <div v-if="momentPointsCurrency === 'points'">
                  <quick-edit v-if="editable === true && 
                                    ((parent === 'auto-moment' && checkPermission('core.automoment.editpoints')) || (parent === 'moment' && checkPermission('loyalty.moments.editpoints')))"
                              type="number"
                              step="5"
                              mode="ignore"
                              :buttonOkText="$t('communications.Set_points')"
                              :buttonCancelText="$t('Dismiss')"
                              v-model="newMomentPoints" @input="updateMomentPoints()">
                    <span class="count d-block">{{momentPoints}}</span>
                    <i class="edit fas fa-pen"/>
                  </quick-edit>
                  <span v-else class="count d-block">{{momentPoints}}</span>
                </div>
                <div v-else>
                  <span class="count d-block">{{formatToCurrency(momentPoints)}}</span>
                </div>                
              </div>
            </div>
            <div class="h-100 pointer currency_switch" @click="switchEmployeesPointsCurrency()">
              <div class="h-100 d-flex align-items-center justify-content-center flex-column">
                <div class="d-flex justify-content-center">
                  <i class="fa-solid fa-repeat"></i>
                </div>
                <div class="switch_label">
                  <span><b>{{$t('To')}} {{this.momentPointsCurrency === 'points' ? 'euro' : 'points'}}</b></span>
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'MomentPoints',
  props: ['platformPermissions', 'parent', 'points', 'eventIndex', 'boxed', 'editable'],
  components: {
    QuickEdit
  },
  watch: {
    $props: {
      handler() {
        if(this.points) {
          this.momentPoints = this.points;
          this.newMomentPoints = this.points;
        }
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,  
      newMomentPoints: 0,
      momentPoints: 0,
      momentAmount: 0,
      momentPointsCurrency: 'points',
      environmentTag: null
    }
  },
  methods: {
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(points/10);
    },    
    updateMomentPoints() {
      this.momentPoints = this.newMomentPoints;
      // Create eventPointsUpdate object for event emit
      let eventPointsUpdate = {event_index: this.eventIndex, new_event_points: this.momentPoints}
      // Emit the event_points_updated event 
      this.$bus.$emit('event_points_updated', eventPointsUpdate);
    },
    switchEmployeesPointsCurrency() {
      if(this.momentPointsCurrency === 'points') {
        this.momentPointsCurrency = 'euro';
      } else {
        this.momentPointsCurrency = 'points';
      }
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }        
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
  }
}
</script>